<template>
  <div class="wameed-dashboard-page-content">
    <work-header
      v-if="getAllApplicants"
      @applyFilter="applyFilter"
      @resetFilter="resetFilter"
      @orderApplicants="orderApplicants"
      :content="content"
    />

    <section
      v-if="getAllApplicants && getAllApplicants.length > 0"
      class="
        wameed-dashboard-page-content_body
        d-flex
        flex-column
        justify-content-between
      "
    >
      <div class="d-flex flex-wrap flex-1 px-2">
        <b-col
          lg="6"
          md="6"
          class="px-0 py-3"
          v-for="(item, i) in getAllApplicants"
          :key="i"
        >
          <works-card
            :id="item.id"
            :avatar="item.gender == 1 ? `man.png` : `woman.png`"
            :title="item.name"
            :subTitle="item.original_skills[0].title"
            :date="item.created_at"
            :count="item.count_skills + ' ' + $t('skills')"
            :subDescription="
              item.experience.title + ' ' + $t('experience_years')
            "
            :readStatus="item.is_read == 0"
            @cardAction="applicationDetails"
          />
        </b-col>
      </div>
      <wameed-pagination
        v-if="getTotalApplicants.totalItems"
        v-model="filterData.page"
        :page="filterData.page"
        @changePage="changePage"
        :items="allPage"
        :totalItems="getTotalApplicants.totalItems"
        :per_page="filterData.per_page"
        @changeCurrentPage="changeCurrentPage"
      />
    </section>
    <wameed-no-data
      v-if="getAllApplicants && getAllApplicants.length < 1"
      icon="nodata-icon"
      :title="$t('no_data')"
      :subTitle="$t('no_data_subtitle_applications')"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import WameedPagination from "@/components/WameedPagination.vue";
import WameedNoData from "@/components/WameedNoData.vue";
import WorksCard from "@/components/pages/works/WorksCard.vue";
import WorkHeader from "@/components/pages/works/WorkHeader.vue";

export default {
  components: {
    WameedPagination,
    WameedNoData,
    WorksCard,
    WorkHeader,
  },
  props: {
    isJob: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      allPage: [],
      filterData: {
        gender: "",
        experience: "",
        original_skill: "",
        other_skills: "",
        job: "",
        page: 1,
        per_page: 20,
        order_by: "name",
        search: "",
      },
      content: [
        {
          title: "main_skills",
          key: "original_skill",
          id: "id",
          name: "title",
          count: "count_applicants",
          selectedData: [],
          data: [],
        },
        {
          title: "sub_skills",
          id: "id",
          name: "title",
          count: "count_applicants",
          key: "other_skills",
          selectedData: [],
          data: [],
        },
        {
          title: "experience_level",
          id: "id",
          name: "title",
          count: "count_applicants",
          key: "experience",
          selectedData: [],
          data: [],
        },
        {
          title: "gender",
          id: "id",
          name: "title",
          count: null,
          key: "gender",
          selectedData: [],
          translate: true,
          data: [
            { title: "male", id: 1 },
            { title: "female", id: 2 },
          ],
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getAllApplicants: "getAllApplicants",
      getTotalApplicants: "getTotalApplicants",
      getAllExperience: "getAllExperience",
      getAllSkills: "getAllSkills",
    }),
  },
  watch: {
    getTotalApplicants(newVal) {
      this.allPage = [];
      for (var i = 1; i <= newVal.totalPages; i++) {
        this.allPage.push({
          title: this.$i18n.t("page") + " / " + i,
          id: i,
        });
      }
    },
    getAllExperience(newVal) {
      var experience = this.content.findIndex(
        (item) => item.key == "experience"
      );
      if (experience > -1) {
        this.content[experience].data = newVal;
      }
    },
    getAllSkills(newVal) {
      var original_skill = this.content.findIndex(
        (item) => item.key == "original_skill"
      );
      var other_skills = this.content.findIndex(
        (item) => item.key == "other_skills"
      );
      if (original_skill > -1) {
        this.content[original_skill].data = newVal;
      }
      if (other_skills > -1) {
        this.content[other_skills].data = newVal;
      }
    },
  },
  created() {
    if (this.isJob) {
      this.filterData.job = this.$router.currentRoute.params.id;
    }
    this.loadData();
    this.loadExperience();
    this.loadSkills();
  },
  methods: {
    ...mapActions({
      loadApplicants: "loadApplicants",
      loadExperience: "loadExperience",
      loadSkills: "loadSkills",
    }),

    changeCurrentPage(item) {
      this.filterData = {
        ...this.filterData,
        page: item.id,
      };
      this.loadData();
    },
    changePage(event) {
      this.filterData = {
        ...this.filterData,
        page: event,
      };
      this.loadData();
    },
    loadData() {
      this.loadApplicants(this.filterData);
    },
    applicationDetails(id) {
      this.$router.push({
        name: "applicant-details",
        params: { lang: this.$i18n.locale, id: id },
      });
    },
    applyFilter() {
      this.content.forEach((item) => {
        this.filterData[item.key] = item.selectedData.toString();
      });
      this.loadData();
    },
    resetFilter() {
      this.content.forEach((item) => {
        item.selectedData = [];
        this.filterData[item.key] = item.selectedData.toString();
      });
      this.loadData();
    },
    orderApplicants(item) {
      this.filterData = {
        ...this.filterData,
        ...item,
      };
      this.loadData();
    },
  },
};
</script>
 
