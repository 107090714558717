<template>
   <fetch-applicants :isJob="false" />
</template>

<script> 
import FetchApplicants from '@/components/pages/applicants/FetchApplicants.vue';
export default {
  components: { FetchApplicants },
};
</script>
 
